import React from "react";

import Dropdown from "components/Dropdown";
import { File } from "interfaces/file";
import { ReactSelectObject } from "interfaces/select";

interface FileStatusFilterProps {
  fileStatusOptions: ReactSelectObject[];
  onChange?: (selected: ReactSelectObject[]) => any;
  isDisabled?: boolean;
  dataQa?: string;
  value: any;
  isSearchable?: boolean;
}

const FileStatusFilter: React.FunctionComponent<FileStatusFilterProps> = ({
  fileStatusOptions,
  onChange,
  isDisabled,
  value,
  isSearchable,
}) => (
  <Dropdown
    containerStyles={{ width: "300px", minWidth: 230, marginLeft: 10 }}
    placeholder="Select File Status"
    options={fileStatusOptions}
    onChange={onChange}
    isDisabled={isDisabled}
    value={value}
    isMulti={true}
    closeMenuOnSelect={false}
    blurInputOnSelect={false}
    isSearchable={isSearchable}
  />
);

export const filterFilesByStatus = (selectedStatus: ReactSelectObject[], files: File[]) => {
  const filteredFiles: File[] = [];
  if (selectedStatus.length > 0) {
    for (const fileStatus of selectedStatus) {
      for (const file of files) {
        if (file.extension.toUpperCase() === fileStatus.value.toUpperCase()) {
          filteredFiles.push(file);
        }
      }
    }
    return filteredFiles;
  } else {
    return files;
  }
};

export default FileStatusFilter;
