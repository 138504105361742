import { RouteProps } from "containers/App";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { logout } from "state/auth";
import { removeItem } from "helpers/storage";
import Constants from "helpers/constants";

type LogoutProps = PropsFromRedux & RouteProps;

class Logout extends React.Component<LogoutProps> {
  public async componentDidMount() {
    removeItem(Constants.IRIS_DOWNLOADS_QUERY);
    removeItem(Constants.MY_STORES_QUERY);
    this.props.logout();

    // Clear service worker cache
    if ('caches' in window) {
      const cacheNames = await caches.keys();
      for (const name of cacheNames) {
        await caches.delete(name);
      }
    }

    // Redirect to login page after logout
    setTimeout(() => {
      window.location.href = "/signin"; // Change this to your login page
    }, 500);
  }

  public render() {
    return null;
  }
}

const connecter = connect(null, { logout });
type PropsFromRedux = ConnectedProps<typeof connecter>;

export default connecter(Logout);
