import cx from "classnames";
import React, { useState } from "react";
import TagManager from "react-gtm-module";
import { connect } from "react-redux";
import {
  Route as DefaultRoute,
  RouteProps as DefaultRouteProps,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import blueHelpSvg from "assets/img/help-blue.svg";
import grayHelpSvg from "assets/img/help-dark-gray.svg";
import About from "components/About";
import ErrorBoundary from "components/ErrorBoundary";
import Icon from "components/Icon";
import Link from "components/Link";
import { LogoLink } from "components/Logo";
import ManageAllData from "components/ManageAllData";
import Config, { hasFeature } from "config";
import CreateProject from "containers/CreateProject";
import DirDownloads from "containers/DirDownloads";
import Downloads from "containers/Downloads";
import HomePage from "containers/HomePage";
import Logout from "containers/Logout";
import ManageAnnouncements from "containers/ManageAnnouncements";
import ManageChains from "containers/ManageChains";
import ManageFiles from "containers/ManageFiles";
import ManageMappingData from "containers/ManageMappingData";
import ManageStores from "containers/ManageStores";
import ManageSurveys from "containers/ManageSurveys";
import ManageUsers from "containers/ManageUsers";
import MyAnnouncements from "containers/MyAnnouncements";
import MyFeedback from "containers/MyFeedback";
import MyStores from "containers/MyStores";
import MySurveys from "containers/MySurveys";
import MyWork from "containers/MyWork";
import Navigation, { groups } from "containers/Navigation";
import NavigationBreadcrumb from "containers/NavigationBreadcrumb";
import NearbyStores from "containers/NearbyStores";
import PageContainer from "containers/PageContainer";
import Profile from "containers/Profile";
import Project from "containers/Project";
import Projects from "containers/Projects";
import ReviewFeedback from "containers/ReviewFeedback";
import SignIn from "containers/SignIn";
import StarredPage from "containers/StarredPage";
import Store from "containers/Store";
import StoreResetFeedback from "containers/StoreResetFeedback";
import StoreResetStatus from "containers/StoreResetStatus";
import Support from "containers/Support";
import UploadAttachments from "containers/UploadAttachments";
import ValidationCompletion from "containers/ValidationCompletion";
import { PermissionsContext } from "context";
import Constants from "helpers/constants";
import { useIdleTimer } from "react-idle-timer";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { State } from "state";
import { PageName } from "state/analytics";
import { AuthUser } from "state/auth_user";
import { Permission } from "state/permissions";
import { UserRole } from "types/roles";
import SearchBar from "../components/SearchBar";
import "../styles/modules/_toast-container.scss";

interface LayoutProps {
  currentRoute?: string;
}

const Layout: React.FunctionComponent<LayoutProps> = ({ children, currentRoute }) => {
  const [state, setState] = useState<string>("Active");

  const onIdle = () => {
    setState("Idle");
    console.log("Idle -> Logout");
    window.location.href = "/logout";
  };

  const onActive = () => {
    setState("Active");
    console.log("active");
  };

  const onAction = () => {};

  useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: parseInt(Config.IDLE_TIMEOUT), // 24 hours -> 24*60*60*1000 -> 86 400 000
    throttle: 500,
    crossTab: true,
  });

  return (
    <section className="grid-container">
      <section className="grid-item logo" data-qa="logo">
        <LogoLink />
      </section>
      <section className="grid-item empty" />
      <section className="grid-item search">
        <SearchBar />
      </section>
      <section className="grid-item support">
        <a
          href="https://brewbox360.zendesk.com/hc/en-us/requests/new"
          target="_blank"
          rel="noreferrer noopener"
          style={{ color: "#3c6df1" }}
        >
          <Icon src={blueHelpSvg} small={true} />
          <span>Request Support</span>
        </a>
      </section>
      <section className="grid-item support-documents">
        <Link
          to="/support"
          className={cx("header-link", {
            "header-link-active": currentRoute === "/support",
          })}
          data-qa="header-link-support"
        >
          <span>Support Documents</span>
        </Link>
      </section>
      <section className="grid-item profile">
        <Profile />
      </section>
      <section className="grid-item grid-navigation">
        <Navigation />
      </section>
      <section className="grid-item breadcrumb">
        <NavigationBreadcrumb />
      </section>
      <section className="grid-item main">{children}</section>
    </section>
  );
};

export interface RouteProps extends DefaultRouteProps {
  pageName: PageName;
}

const Route: React.FunctionComponent<RouteProps> = ({ pageName, ...props }) => {
  // props.location should always be present for top-level pages
  // usually pages using withRouter
  if (props.location) {
    props.location.state = { pageName };
    if (pageName !== "Sign In" && pageName !== "Sign Out") {
      sessionStorage.setItem('redirectedPath', props.location.pathname)
    }
  }

  return <DefaultRoute {...props} />;
};

export interface PrivateRouteProps extends RouteProps {
  isAuthenticated: boolean;
  allowedRoles: UserRole[];
  role?: UserRole;
  requiredUserLevelPermissions?: any[];
  userLevelPermissions?: any[];
  component: React.ComponentType<RouteProps>;
}

const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = ({
  isAuthenticated = false,
  allowedRoles = [],
  role,
  requiredUserLevelPermissions = [],
  userLevelPermissions = [],
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      component={(props: RouteProps) => {
        if (isAuthenticated) {
          if (requiredUserLevelPermissions.length) {
            if (
              role === Constants.AB_SYSTEM_ADMIN ||
              requiredUserLevelPermissions.every((required) => {
                return userLevelPermissions.includes(required);
              })
            ) {
              return <Component {...props} />;
            } else {
              throw new Error(Constants.ERROR_403);
            }
          } else if (allowedRoles.some((ar) => ar === role)) {
            return <Component {...props} />;
          } else {
            throw new Error(Constants.ERROR_403);
          }
        }

        return (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

interface AppProps {
  isAuthenticated: boolean;
  user?: AuthUser;
  permissions?: Permission;
}

class App extends React.Component<AppProps> {
  public renderRoutes() {
    const { isAuthenticated, user } = this.props;
    return (
      <>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          theme="light"
          transition={Bounce}
        />
        <Switch>
          {/* Admin Routes */}
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[Constants.AB_SYSTEM_ADMIN]}
            path="/projects"
            pageName="Manage Projects"
            exact={true}
            component={(props: RouteProps) => (
              <Layout>
                <Projects {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[Constants.AB_SYSTEM_ADMIN]}
            path="/create-project/:id"
            pageName="Create Project"
            component={(props: RouteProps) => (
              <Layout>
                <CreateProject {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[Constants.AB_SYSTEM_ADMIN]}
            exact={true}
            path="/manage-chains"
            pageName="Manage Chains"
            component={(props: RouteProps) => (
              <Layout>
                <ManageChains {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[Constants.AB_SYSTEM_ADMIN]}
            exact={true}
            path="/manage-stores"
            pageName="Manage Stores"
            component={(props: RouteProps) => (
              <Layout>
                <ManageStores {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[Constants.AB_SYSTEM_ADMIN]}
            exact={true}
            path="/manage-users"
            pageName="Manage Users"
            component={(props: RouteProps) => (
              <Layout>
                <ManageUsers {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[Constants.AB_SYSTEM_ADMIN]}
            exact={true}
            path="/manage-mapping-data"
            pageName="Manage Mapping Data"
            component={(props: RouteProps) => (
              <Layout>
                <ManageMappingData {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[Constants.AB_SYSTEM_ADMIN]}
            exact={true}
            path="/manage-all-data"
            pageName="Manage All Data"
            component={(props: RouteProps) => (
              <Layout>
                <ManageAllData {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[Constants.AB_SYSTEM_ADMIN]}
            exact={true}
            path="/manage-files"
            pageName="Manage Files"
            component={(props: RouteProps) => (
              <Layout>
                <ManageFiles {...props} />
              </Layout>
            )}
          />
          {/* Admin + CSM Routes */}
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact={true}
            role={user && (user.role as UserRole)}
            allowedRoles={[
              Constants.AB_SYSTEM_ADMIN,
              Constants.AB_PROJECT_ADMIN,
              Constants.AB_REGION_CAT_DIRECTOR,
              Constants.COMPETITOR_CSM,
            ]}
            path="/surveys"
            pageName="Manage Surveys"
            component={(props: RouteProps) => (
              <Layout>
                <ManageSurveys {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact={true}
            role={user && (user.role as UserRole)}
            allowedRoles={[
              Constants.AB_SYSTEM_ADMIN,
              Constants.AB_PROJECT_ADMIN,
              Constants.RETAILER,
            ]}
            path="/announcements"
            pageName="Manage Announcements"
            component={(props: RouteProps) => (
              <Layout>
                <ManageAnnouncements {...props} />
              </Layout>
            )}
          />
          {/* User Routes */}
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[
              Constants.AB_SYSTEM_ADMIN,
              Constants.AB_PROJECT_ADMIN,
              Constants.AB_COLLABORATOR,
              Constants.AB_MERCHANDISER,
              Constants.AB_REGION_CAT_DIRECTOR,
              Constants.AB_WHOLESALER,
              Constants.AB_VIEWER,
              Constants.RETAILER,
              Constants.VIEWER,
              Constants.COMPETITOR_CSM,
              Constants.COMPETITOR_WHOLESALER,
            ]}
            exact={true}
            path="/"
            pageName="Root"
            component={() => {
              // redirect currently logged in user to their default route
              if (user) {
                for (const { routes } of groups[user.role as UserRole]) {
                  for (const route of [...routes]) {
                    if (
                      route.landingPageFor &&
                      route.landingPageFor.some((lpr: string) => user && lpr === user.role)
                    ) {
                      return <Redirect to={{ pathname: route.route }} />;
                    }
                  }
                }
              }
              // if no default route set, send user to My Work page
              return <Redirect to={{ pathname: "/projects/me" }} />;
            }}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[
              Constants.AB_SYSTEM_ADMIN,
              Constants.AB_PROJECT_ADMIN,
              Constants.AB_COLLABORATOR,
              Constants.AB_MERCHANDISER,
              Constants.AB_REGION_CAT_DIRECTOR,
              Constants.AB_WHOLESALER,
              Constants.AB_VIEWER,
              Constants.RETAILER,
              Constants.VIEWER,
              Constants.COMPETITOR_CSM,
              Constants.COMPETITOR_WHOLESALER,
            ]}
            exact={true}
            path="/starred"
            pageName="Home Page"
            component={(props: RouteProps) => (
              <Layout>
                <HomePage {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[
              Constants.AB_SYSTEM_ADMIN,
              Constants.AB_PROJECT_ADMIN,
              Constants.AB_COLLABORATOR,
              Constants.AB_MERCHANDISER,
              Constants.AB_REGION_CAT_DIRECTOR,
              Constants.AB_WHOLESALER,
              Constants.AB_VIEWER,
              Constants.RETAILER,
              Constants.VIEWER,
              Constants.COMPETITOR_CSM,
              Constants.COMPETITOR_WHOLESALER,
            ]}
            exact={true}
            path="/starred/all"
            pageName="Starred Page"
            component={(props: RouteProps) => (
              <Layout>
                <StarredPage {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[
              Constants.AB_SYSTEM_ADMIN,
              Constants.AB_PROJECT_ADMIN,
              Constants.AB_COLLABORATOR,
              Constants.AB_MERCHANDISER,
              Constants.AB_REGION_CAT_DIRECTOR,
              Constants.AB_WHOLESALER,
              Constants.AB_VIEWER,
              Constants.RETAILER,
              Constants.VIEWER,
              Constants.COMPETITOR_CSM,
              Constants.COMPETITOR_WHOLESALER,
            ]}
            exact={true}
            path="/projects/me"
            pageName="My Projects"
            component={(props: RouteProps) => (
              <Layout>
                <MyWork {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[
              Constants.AB_SYSTEM_ADMIN,
              Constants.AB_PROJECT_ADMIN,
              Constants.AB_COLLABORATOR,
              Constants.AB_MERCHANDISER,
              Constants.AB_REGION_CAT_DIRECTOR,
              Constants.AB_WHOLESALER,
              Constants.AB_VIEWER,
              Constants.RETAILER,
              Constants.VIEWER,
              Constants.COMPETITOR_CSM,
              Constants.COMPETITOR_WHOLESALER,
            ]}
            path="/projects/:projectId/stores/:storeId"
            pageName="Store Detail"
            component={(props: RouteProps) => (
              <Layout>
                <Store {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[
              Constants.AB_SYSTEM_ADMIN,
              Constants.AB_PROJECT_ADMIN,
              Constants.AB_COLLABORATOR,
              Constants.AB_MERCHANDISER,
              Constants.AB_REGION_CAT_DIRECTOR,
              Constants.AB_WHOLESALER,
              Constants.AB_VIEWER,
              Constants.RETAILER,
              Constants.VIEWER,
              Constants.COMPETITOR_CSM,
              Constants.COMPETITOR_WHOLESALER,
            ]}
            path="/projects/:id"
            pageName="Project Detail"
            component={(props: RouteProps) => (
              <Layout>
                <Project {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[
              Constants.AB_SYSTEM_ADMIN,
              Constants.AB_PROJECT_ADMIN,
              Constants.AB_COLLABORATOR,
              Constants.AB_MERCHANDISER,
              Constants.AB_REGION_CAT_DIRECTOR,
              Constants.AB_WHOLESALER,
              Constants.AB_VIEWER,
              Constants.RETAILER,
              Constants.VIEWER,
              Constants.COMPETITOR_CSM,
              Constants.COMPETITOR_WHOLESALER,
            ]}
            exact={true}
            path="/downloads"
            pageName="Downloads"
            component={(props: RouteProps) => (
              <Layout>
                <Downloads {...props} />
              </Layout>
            )}
          />

          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[Constants.AB_SYSTEM_ADMIN, Constants.AB_WHOLESALER,Constants.AB_PROJECT_ADMIN]}
            requiredUserLevelPermissions={["access_DIR"]}
            userLevelPermissions={user && user.user_level_permissions}
            exact={true}
            path="/iris-downloads"
            pageName="IRIS Downloads"
            component={(props: RouteProps) => (
              <Layout>
                <DirDownloads {...props} />
              </Layout>
            )}
          />

          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[
              Constants.AB_SYSTEM_ADMIN,
              Constants.AB_PROJECT_ADMIN,
              Constants.AB_COLLABORATOR,
              Constants.AB_MERCHANDISER,
              Constants.AB_REGION_CAT_DIRECTOR,
              Constants.AB_WHOLESALER,
              Constants.AB_VIEWER,
              Constants.RETAILER,
              Constants.VIEWER,
              Constants.COMPETITOR_CSM,
              Constants.COMPETITOR_WHOLESALER,
            ]}
            exact={true}
            path="/stores/me"
            pageName="My Stores"
            component={(props: RouteProps) => (
              <Layout>
                <MyStores {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[
              Constants.AB_SYSTEM_ADMIN,
              Constants.AB_PROJECT_ADMIN,
              Constants.AB_COLLABORATOR,
              Constants.AB_MERCHANDISER,
              Constants.AB_REGION_CAT_DIRECTOR,
              Constants.AB_WHOLESALER,
              Constants.COMPETITOR_CSM,
              Constants.COMPETITOR_WHOLESALER,
            ]}
            exact={true}
            path="/my-feedback"
            pageName="My Feedback"
            component={(props: RouteProps) => (
              <Layout>
                <MyFeedback {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[
              Constants.AB_SYSTEM_ADMIN,
              Constants.AB_PROJECT_ADMIN,
              Constants.AB_COLLABORATOR,
              Constants.AB_MERCHANDISER,
              Constants.AB_REGION_CAT_DIRECTOR,
              Constants.AB_VIEWER,
              Constants.RETAILER,
            ]}
            exact={true}
            path="/validation-completion"
            pageName="Validation Completion"
            component={(props: RouteProps) => (
              <Layout>
                <ValidationCompletion {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[
              Constants.AB_SYSTEM_ADMIN,
              Constants.AB_PROJECT_ADMIN,
              Constants.AB_COLLABORATOR,
              Constants.AB_MERCHANDISER,
              Constants.AB_REGION_CAT_DIRECTOR,
              Constants.AB_VIEWER,
              Constants.RETAILER,
            ]}
            exact={true}
            path="/review-feedback"
            pageName="Review Feedback"
            component={(props: RouteProps) => (
              <Layout>
                <ReviewFeedback {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            exact={true}
            role={user && (user.role as UserRole)}
            allowedRoles={[
              Constants.AB_SYSTEM_ADMIN,
              Constants.AB_PROJECT_ADMIN,
              Constants.AB_COLLABORATOR,
              Constants.AB_MERCHANDISER,
              Constants.AB_REGION_CAT_DIRECTOR,
              Constants.AB_WHOLESALER,
              Constants.RETAILER,
              Constants.VIEWER,
            ]}
            path="/store-reset-status"
            pageName="Store Reset Status"
            component={(props: RouteProps) => (
              <Layout>
                <StoreResetStatus {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[
              Constants.AB_SYSTEM_ADMIN,
              Constants.AB_PROJECT_ADMIN,
              Constants.AB_COLLABORATOR,
              Constants.AB_MERCHANDISER,
              Constants.AB_REGION_CAT_DIRECTOR,
              Constants.AB_VIEWER,
              Constants.RETAILER,
            ]}
            exact={true}
            path="/store-reset-feedback"
            pageName="Reset Feedback"
            component={(props: RouteProps) => (
              <Layout>
                <StoreResetFeedback {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[
              Constants.AB_SYSTEM_ADMIN,
              Constants.AB_PROJECT_ADMIN,
              Constants.AB_COLLABORATOR,
              Constants.AB_MERCHANDISER,
              Constants.AB_WHOLESALER,
              Constants.RETAILER,
            ]}
            exact={true}
            path="/surveys/me"
            pageName="My Surveys"
            component={(props: RouteProps) => (
              <Layout>
                <MySurveys {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[
              Constants.AB_SYSTEM_ADMIN,
              Constants.AB_PROJECT_ADMIN,
              Constants.AB_COLLABORATOR,
              Constants.AB_MERCHANDISER,
              Constants.AB_REGION_CAT_DIRECTOR,
              Constants.AB_WHOLESALER,
              Constants.AB_VIEWER,
              Constants.RETAILER,
              Constants.VIEWER,
              Constants.COMPETITOR_CSM,
              Constants.COMPETITOR_WHOLESALER,
            ]}
            exact={true}
            path="/support"
            pageName="Support"
            component={(props: RouteProps) => (
              <Layout currentRoute="/support">
                <Support {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[
              Constants.AB_SYSTEM_ADMIN,
              Constants.AB_PROJECT_ADMIN,
              Constants.AB_COLLABORATOR,
              Constants.AB_MERCHANDISER,
              Constants.AB_REGION_CAT_DIRECTOR,
              Constants.AB_WHOLESALER,
              Constants.AB_VIEWER,
              Constants.VIEWER,
              Constants.COMPETITOR_CSM,
              Constants.COMPETITOR_WHOLESALER,
            ]}
            exact={true}
            path="/announcements/me"
            pageName="My Announcements"
            component={(props: RouteProps) => (
              <Layout>
                <MyAnnouncements {...props} />
              </Layout>
            )}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            role={user && (user.role as UserRole)}
            allowedRoles={[Constants.AB_SYSTEM_ADMIN, Constants.AB_PROJECT_ADMIN]}
            exact={true}
            path="/upload-attachments"
            pageName="Upload Attachments"
            component={(props: RouteProps) => (
              <Layout>
                <UploadAttachments {...props} user={user || undefined} />
              </Layout>
            )}
          />
          {hasFeature(Config.FEATURES, "nearbystores") && (
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              role={user && (user.role as UserRole)}
              allowedRoles={[
                Constants.AB_SYSTEM_ADMIN,
                Constants.AB_PROJECT_ADMIN,
                Constants.AB_COLLABORATOR,
                Constants.AB_MERCHANDISER,
                Constants.AB_REGION_CAT_DIRECTOR,
                Constants.AB_WHOLESALER,
                Constants.AB_VIEWER,
                Constants.RETAILER,
                Constants.VIEWER,
                Constants.COMPETITOR_CSM,
                Constants.COMPETITOR_WHOLESALER,
              ]}
              exact={true}
              path="/nearby-stores"
              pageName="Nearby Stores"
              component={(props: RouteProps) => (
                <Layout>
                  <NearbyStores {...props} />
                </Layout>
              )}
            />
          )}
          {/* Public Routes */}
          <Route
            exact={true}
            path="/signin"
            pageName="Sign In"
            component={(props: RouteProps) => <SignIn {...props} />}
          />
          <Route
            exact={true}
            path="/logout"
            pageName="Sign Out"
            component={(props: RouteProps) => <Logout {...props} />}
          />
          <Route exact={true} path="/about-us" pageName="About Us" component={About} />
          <Route
            pageName="Error"
            component={() => {
              // route invalid paths to the 404 handler
              throw new Error(Constants.ERROR_404);
            }}
          />
        </Switch>
      </>
    );
  }

  public render() {
    const { user, permissions } = this.props;

    if (Config.GOOGLE_TAG_MANAGER_ID) {
      const gtmArgs = {
        gtmId: Config.GOOGLE_TAG_MANAGER_ID,
        dataLayer: {
          user: user,
        },
      };

      TagManager.initialize(gtmArgs);
    }
    const routes = this.renderRoutes();
    return (
      <Router>
        <ErrorBoundary>
          <PageContainer>
            {permissions ? (
              <PermissionsContext.Provider value={permissions}>
                {routes}
              </PermissionsContext.Provider>
            ) : (
              routes
            )}
          </PageContainer>
        </ErrorBoundary>
      </Router>
    );
  }
}

const mapStateToProps = (state: State) => {
  TagManager.dataLayer({
    dataLayer: {
      user: state.user,
    },
  });

  return {
    isAuthenticated: !!state.auth.content,
    user: state.auth_user.content,
    permissions: state.permissions.content,
  };
};

export default connect(mapStateToProps)(App);
